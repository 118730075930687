.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: url('../public/assets/images/bg.jpg') no-repeat center center fixed;
  background-size: cover;
  
  
}

.overlay{
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background-color:rgba(0, 70, 173, .55);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Header{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  padding-bottom: 40px;
}

.card{
  width: 100%;
  max-width: 550px;
  padding: 2rem;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  background-color:rgba(255,255,255,0.75);
  border-radius:16px;
  box-shadow: 0 4px 30px rgba(227, 246, 255, 0.2);
  backdrop-filter: blur(11px);
  -webkit-backdrop-filter: blur(11px);
}

.card-header{
  width: 100%;
  max-width: 400px;
  margin: 10px auto;
}
.card-header h3{
  font-size: 22px;
  color: #3C3C3C
}
.card-body{
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}


@media screen and (max-width: 767px){
  .card{
    max-width: 280px;
  }
}