@keyframes skeleton-loading{
    0% {
        background-color: #D9D9D9;
      }
      100% {
        background-color: #C4C4C4;
      }
}

.skeletonApp{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
.skeletonOverlay{
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    background-color:rgba(225, 225, 225, .95);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.skeletonHeader{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px;
    padding-bottom: 40px;
}
.skeletonLogo{
    width: 172px;
    height: 44px;
    background-color:#ccc;
    border-radius: 4px;
    animation: skeleton-loading 1s linear infinite alternate;
}
.skeletonCard{
    width: 100%;
    max-width: 550px;
    height: 368px;
    padding: 2rem;
    border: 1px solid #efefef;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color:rgba(255,255,255,0.75);
    border-radius:16px;

}
.skeletonCardHeader{
    width: 100%;
    max-width: 400px;
    height: 106px;
    margin: 10px auto;
}
.skeletonCardHeaderTitle{
width: 100%;
height: 62px;
border-radius: 4px;
/* background-color:#ccc; */
margin: 22px auto;
animation: skeleton-loading 1s linear infinite alternate;
}
.skeletonCardBody{
    width: 100%;
    max-width: 400px;
    height: 200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.skeletonCardHeaderParagraph{
    width: 100%;
    max-width: 200px;
    height: 45px;
    border-radius: 4px;
    /* background-color:#ccc; */
    margin: 16px auto;
    animation: skeleton-loading 1s linear infinite alternate;
}
.skeletonCountDownRow{
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.skeletonCountDownCol{
    width: 100%;
    max-width: 60px;
    height: 68px;
    /* background-color: #ccc; */
    border-radius: 4px;
    animation: skeleton-loading 1s linear infinite alternate;
}