.footer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 40px;
    color: #fff;
}

.LangSwitcherList{
    display: flex;
    flex-direction:row;
    list-style-type: none;
    padding: 0;
    margin: 0 auto 8px auto;
}
.LangSwitcherItem:nth-child(1)::after{
    content: " - ";
}
.LangSwitcherItem{
    padding-left: 4px;
    cursor: pointer;
}
