.btn {
    cursor: pointer;
    width: 100%;
    height: 50px;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: stretch;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 16px 24px;
    margin: 20px auto;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.5px;
    box-shadow: 0 10px 24px 0 rgba(184, 233, 254, 0.6); 
    background-image: linear-gradient(164deg, #3283ff 18%, #254aba 80%, #254aba 80%);
}

.btn:hover,
.btn:focus {
    box-shadow: 0 10px 24px 0 rgba(37, 74, 186, 0.6);
}