.expiredNotice{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.expiredNotice > span{
    min-height: 48px;
}

.cardActions{
    width: 100%;
    max-width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}