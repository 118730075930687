.countdown{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #3C3C3C;
}
.countdownDanger{
    color: #32A9D5;
}
.countdownZero{
    color: #818181;
}

.num{
    font-size: 32px;
    letter-spacing: -0.8px;
    font-weight: 300;
}
.timeLabel{
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
    
}